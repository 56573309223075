.dialog-form {
  &__item {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    label {
      width: 100%;
      margin-bottom: 10px;
    }
  }
  &__input {
    width: 100%;
  }
}
