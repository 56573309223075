.user-profile {
  &__header {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 40px;
  }
  &__footer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: end;
  }
  &__policy-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-bottom: 20px;
    span {
      font-size: 14px;
      max-width: 280px;
      text-align: right;
    }
    
  }
  &__avatar-container {
    width: 160px;
    height: 160px;
    margin-right: 40px;
    position: relative;
    img {
      width: 100%;
      border-radius: 100px;
    }
  }
  &__avatar-edit {
    position: absolute;
    bottom: 0;
    right: 0;
    button {
      background-color: $brand-color-2;
      border: 1px solid $brand-color-1;
      color: #ffffff;
    }
    .p-button.p-fileupload-choose {
      border-radius: 100px;
    }
  }
  &__loader {
    width: 100%;
  }
  .form-container {
    padding: 40px;
    width: 960px;
    margin-bottom: 0;
    &__subscriptions {
      &--button {
        margin-left: auto;
        margin-right: 28px;
        margin-top: 12px;
      }
      .user-profile__footer {
        margin-top: 20px;
      }
      &--warning-tag {
        margin-bottom: 20px;
      }
    }
    .p-field {
      width: 45%;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 20px;
      label {
        width: 100%;
        margin-bottom: 10px;
      }
      input {
        width: 100%;
      }
      p-calendar {
        width: 100%;
        .p-calendar {
          width: 100%;
        }
      }
      .p-inputwrapper {
        width: 100%;
      }
    }
    .form-row__2-col {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
}

.pricing-box {
  position: relative;
  display: flex;
  width: 250px;
  height: 310px;
  border-radius: 10px;
  margin: 18px;
  padding: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: $brand-color-1;
  border-radius: 30px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  z-index: 100;
  transition: transform 0.3s ease, border-color 0.3s ease;

  &--active,
  &:hover {
    border-color: $brand-color-2;
    background-color: $theme-color-9;
    cursor: pointer;
    transform: translateY(-6px);
    hr {
      width: 40%;
    }
  }

  &__title {
    font-size: 24px;
    font-weight: bold;
  }

  hr {
    width: 20%;
    transition: width 0.3s ease;
  }

  span {
    width: 100%;
    display: list-item;
    text-align: center;
    list-style-type: none;
  }

  fa-icon {
    color: green;
    margin-right: 5px;
  }

  img {
    position: absolute;
    top: -42px;
    right: -42px;
    width: 94px;
  }
}

.subscription-info-line {
  width: 100%;
  margin-bottom: 10px;
  label {
    font-weight: bold;
  }
}

.upgrade-plan-modal {
  display: flex;
  flex-wrap: wrap;
  padding-top: 20px;
  justify-content: center;
  .pricing-box {
    &--active,
    &:hover {
      background-color: $theme-color-7;
    }
  }
  &__footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 16px;
  }
}

.topup-modal {
  &__item {
    padding: 6px 80px;
  }
  &__footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 16px;
  }
}

@media (max-width: 1200px) {
  .user-profile {
    &__tabs {
      max-width: 90%;
    }
    .form-container {
      width: 100%;
    }
  }
}

@media (max-width: 576px) {
  .user-profile {
    &__tabs {
      max-width: 90%;
    }
    &__footer {
      flex-wrap: wrap;
      .p-button {
        width: 100%;
        margin-bottom: 10px;
      }
      .p-button.mr-4 {
        margin-right: 0 !important;
      }
    }
    .form-container .p-field {
      width: 100%;
    }
  }
}