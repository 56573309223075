.text-editor {
  width: 100%;
  &__editor {
    width: 100%;
  }
  .p-editor-content {
    height: 70vh;
  }

  &__header {
    margin-bottom: 20px;
    input {
      margin-right: 10px;
    }
  }
  .ql-formats {
    fa-icon {
      color: rgba(255, 255, 255, 0.6);
      &:hover {
        color: white;
      }
    }
    &.no-margin {
      margin-right: 0 !important;
    }
  }
  .ql-formats-large {
    .ql-picker.ql-size  {
      width: 110px !important;
    }
  }
  .ql-picker.ql-size .ql-picker-item[data-value='small'] {
    &:before {
      font-size: 14px !important;
    }
  }
  .ql-tooltip {
    background-color: $theme-color-9 !important;
    border-radius: 6px !important;
    border: 1px solid $theme-color-8 !important;
    box-shadow: none !important;
    color: $theme-color-2 !important;
    input {
      background-color: $theme-color-7 !important;
      border-radius: 6px !important;
      color: $theme-color-2 !important;
    }
  }
  .ql-editor {
    li {
      font-size: 16px;
    }
  }
}
.ia-tool {
  top: 0;
  position: absolute;
  visibility: hidden;
  &__input-container {
    border: 1px solid $theme-color-8;
    border-radius: 6px;
    display: flex;
    flex-wrap: wrap;
    max-width: 552px;
    &:hover, &.focus{
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem rgba(45, 212, 191, 0.2);
      border-color: $brand-color-2;
    }
  }
  textarea {
    height: 40px;
    background-color: $theme-color-7;
    border: none;
    width: 500px;
    &:focus {
      border: none;
      box-shadow: none;
      outline: none;
    }
  }

  &__actions {
    visibility: hidden;
    border: 1px solid $border-color;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    width: fit-content;
    background-color: $theme-color-9;
  }
  &__action {
    padding: 10px 14px;
    border-top: 1px solid $border-color;
    display: flex;
    justify-content: space-between;
    &:first-of-type {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }
    &:last-of-type {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }
    &:hover {
      cursor: pointer;
      box-shadow: 0 0 0 0.2rem rgba(45, 212, 191, 0.2);
      border: 1px solid $brand-color-2;
    }
    .ng-fa-icon {
      margin-left: 20px;
    }
    &--shorter {
      .ng-fa-icon {
        transform: rotate(45deg);
      }
    }
     &--delete {
      .ng-fa-icon {
        color: $danger-dark-color;
      }
     }
     &--accept {
      .ng-fa-icon {
        color: $action-dark-color;
      }
     }
  }
  &__input-send {
    padding: 16px;
    background-color: $theme-color-7;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  &__response {
    width: 100%;
    border-top: 1px solid $brand-color-2;
    padding: 10px;
    background-color: $theme-color-7;
    display: flex;
    justify-content: center;
  }
}

.ia-selection {
  position: absolute;
  visibility: hidden;
  border: 1px solid $border-color;
  border-radius: $border-radius-input;
  background-color: $theme-color-9;
  top: 0;
  max-width: 700px;
  &__tooltip {
    display: flex;
  }
  &__tooltip-container {
    display: flex;
    border-left: 1px solid $border-color;
    &:first-of-type {
      border-top-left-radius: $border-radius-input;
      border-bottom-left-radius: $border-radius-input;
      .ia-selection__tool {
        border-top-left-radius: $border-radius-input;
        border-bottom-left-radius: $border-radius-input;
      }
    }
    &:last-of-type {
      border-top-right-radius: $border-radius-input;
      border-bottom-right-radius: $border-radius-input;
      .ia-selection__tool {
        &:last-of-type {
          border-top-right-radius: $border-radius-input;
          border-bottom-right-radius: $border-radius-input;
        }
      }
    }
  }
  &__tool {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    &:hover {
      cursor: pointer;
      background-color: $theme-color-7;

      .ia-selection__actions {
        visibility: visible;
      }
    }
    .ng-fa-icon {
      padding: 10px;
      &.rotate {
        transform: rotate(45deg);
      }
    }
    span {
      padding: 10px;
      padding-left: 0;
      width: max-content;
    }
    .logo-img {
      width: 26px;
      height: 26px;
      margin: 0 10px;
    }
  }
  &__actions {
    display: flex;
    flex-direction: column;
    background-color: $theme-color-9;
    border: 1px solid $theme-color-8;
    border-radius: $border-radius-input;
    position: absolute;
    top: 40px;
    left: 0;
    visibility: hidden;
  }
  &__action {
    display: flex;
    justify-content: space-between;
  }
  &__action-label {
    display: flex;
    margin-right: 10px;
  }

  &__response-container {
    width: 698px;
    display: flex;
    flex-wrap: wrap;
  }
  &__response {
    width: 100%;
    text-indent: 0px;
    padding: 16px;
    display: flex;
    justify-content: center;
    span {
      width: 100%;
    }
  }
  &__input {
    width: 100%;
    display: flex;
    border-top: 1px solid $border-color;
    textarea {
      width: 100%;
      height: 40px;
      background: none;
      border: none;
      width: 100% ;
      &:focus {
        border: none;
        box-shadow: none;
        outline: none;
      }
    }
  }

  &__input-send {
    padding: 16px;
  }
  &__select-language {
    position: absolute;
    left: 40%;
    padding: 20px;
    border: 1px solid $border-color;
    border-radius: $border-radius-input;
    background-color: $theme-color-9;
    .p-dropdown {
      margin-bottom: 20px;
      width: 100%;
    }
    .p-button {
      width: 100%;
    }
  }
}

.selection-response-actions, .ia-tool-response-actions {
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: $theme-color-9;
  border: 1px solid $theme-color-8;
  border-radius: $border-radius-input;
  &__action {
    padding: 12px;
    border-top: 1px solid $theme-color-8;
    &:first-of-type {
      border-top: none;
    }
    .ng-fa-icon {
      margin-right: 10px;
    }
    &:hover {
      border: 1px solid $brand-color-2;
    }
  }
}

.formula-editor {
  position: absolute;
  visibility: hidden;
  top: 50vh;
  left: 20vw;
  background-color: $theme-color-7;
  border: 1px solid $brand-color-2;
  border-radius: 6px;
  display: flex;
  padding: 2px;
  align-items: center;
  fa-icon {
    color: white;
  }
  &--active {
    visibility: visible;
  }
  &__input-send {
    border-radius: 8px;
    padding: 8px;
    &:hover {
      background: hsla(0, 0%, 70%, 0.3);
      fill: currentColor;
    }
  }
}
math-field::part(virtual-keyboard-toggle) {
  color: white;
}
math-field::part(menu-toggle) {
  display: flex;
  color: white;
}
math-field::part(container) {
  &:focus {
    outline: none;
    border: none;
  }
}
math-field {
  background-color: $theme-color-7;
  width: 100%;
  border: none;
  &:focus {
    outline: none;
    border: none;
  }
}

.ql-editor.ql-blank::before {
  color: $theme-color-2 !important;
  font-size: 16px;
}

@media (max-width: 1200px) {
  .ia-selection {
    max-width: unset;
    &--response-active {
      left: 5vw !important;
    }
    &__response-container {
      width: 90vw;
    }
  }
  .ia-tool {
    width: 80vw;
    left: 10vw !important;
    &__input-container  {
      max-width: unset;
    }
    textarea {
      width: calc(80vw - 60px);
    }
  }
}

@media (max-width: 576px) {
  .container.page.editor-page {
    padding: 10px;
    max-width: 100vw;
    
  }
  .writing-format-dropdown {
    margin-top: 10px;
  }
  .text-editor__header {
    display: flex;
    flex-wrap: wrap;
  }
  .ia-selection {
    &__tool .ng-fa-icon {
      padding: 7px;
    }
    &__response-container {
      overflow-x: hidden;
      max-width: 90vw;
    }
  }
}

.hidden-conainer {
  width: 800px;
  padding: 20px;
  p {
    page-break-inside: avoid;
  }
}