/* we are using reset ruls of bootstrap,
if you need anything override it here 
or add new */
html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  background-color: $theme-color-1;
}

.shared-view {
  width: 100%;
}
.shared-view {
  background-color: $theme-color-1;
}

.all-page {
  display: flex;
}

$scrollbar-width: 8px;
$scrollbar-color: #888;
$scrollbar-hover-color: #555;
$scrollbar-track-color: #f1f1f1;

::-webkit-scrollbar {
  width: $scrollbar-width;
  height: $scrollbar-width;
}

::-webkit-scrollbar-track {
  background: $scrollbar-track-color;
  border-radius: calc($scrollbar-width / 2);
}

::-webkit-scrollbar-thumb {
  background: $scrollbar-color;
  border-radius: calc($scrollbar-width / 2);
}

::-webkit-scrollbar-thumb:hover {
  background: $scrollbar-hover-color;
}

@media (max-width: 1200px) {
  ::-webkit-scrollbar {
    width: 6px; /* Hacer más pequeño para móviles */
    height: 6px;
  }
}