/**
* @desc         overrides default bootstrap styles.
* @requires     none
*/

// convertes px to rem.
@function calculate-rem($pixels) {
  $context: calc(16 * 1px);
  @if (unitless($pixels)) {
    $pixels: calc($pixels * 1px);
  }
  @return calc($pixels / $context * 1rem);
}

// string replace
@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      str-replace(
        str-slice($string, $index + str-length($search)),
        $search,
        $replace
      );
  }

  @return $string;
}
