$opened: 280px;
$closed: 80px;

.navbar {
    height: 100%;
    min-height: 100vh;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: flex-start;
    background-color: $theme-color-4;
    border-right: 1px solid $theme-color-8;

    &.close {
        width: $closed;
    }

    &__item {
        display: flex;
        align-items: center;
        height: 40px;

        .navbar__item-icon {
            width: $closed;
            display: flex;
            justify-content: center;
        }

        .navbar__item-text {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            overflow: hidden;

            span {
                width: 200px;
            }
        }

        &:first-of-type {
            height: 80px;
            margin-top: 18px;

            .navbar__item-icon {
                width: $closed;
                padding: 14px;

                img {
                    width: 100%;
                }
            }

            .navbar__item-text {
                img {
                    width: 180px;
                    padding-top: 6px;
                }
            }
        }

    }
    &__languages {
      margin: 10px 24px;
      display: flex;
      flex-wrap: wrap;
      .p-inputwrapper {
        width: 100%;
      }
    }
}
.mobile-navbar {
    display: none;
}

@media (max-width: 1200px) {
    .shared-view {
        margin-top: 54px;
        min-height: calc(100vh - 54px);
    }
    .navbar {
        display: none;
        min-height: unset;
    }
    .nav-bar {
        width: 100%;
        position: absolute;
        top: 0;
        z-index: 200;
    }
    .mobile-navbar {
        background-color: $theme-color-4;
        display: flex;
        flex-direction: column;
        .navbar-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0.5rem 1rem;
            height: 54px;
            border-bottom: 1px solid $theme-color-8;
            .logo {
                display: flex;
                height: 100%;
                align-items: center;
                .logo-icon {
                    height: 40px;
                    margin-right: 10px;
                }
                .logo-text {
                    height: 30px;
                    position: relative;
                    top: 2px;
                }
            }
        }
    
        .menu {
            list-style: none;
            margin: 0;
            padding: 0;
            background-color: $theme-color-4;
            display: none;
            flex-direction: column;
    
            &.open {
                display: flex;
            }
    
            li {
                border-bottom: 1px solid  $theme-color-8;
                fa-icon {
                    margin-right: 10px;
                }
                .mobile-nav-div {
                    display: block;
                    padding: 14px 10px 14px 40px;
                    text-decoration: none;
                }
            }
        }

        li .mobile-nav-div.tokens-count-mobile {
          display: flex;
          align-items: center;
          .upgrade-plan-button {
            margin-right: 15px;
          }
        }

        .mobile-nav-languaegs {
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          padding: 14px 10px 14px 40px;
        }
    }
}

#nav-icon2 {
    position: relative;
    width: 25px;
    height: 25px;
}

#nav-icon2 span {
  display: block;
  position: absolute;
  height: 5px;
  width: 50%;
  background: $brand-color-1;
  opacity: 1;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

#nav-icon2 span:nth-child(even) {
  left: 50%;
  border-radius: 0 9px 9px 0;
}

#nav-icon2 span:nth-child(odd) {
  left:0px;
  border-radius: 9px 0 0 9px;
}

#nav-icon2 span:nth-child(1), #nav-icon2 span:nth-child(2) {
  top: 0px;
}

#nav-icon2 span:nth-child(3), #nav-icon2 span:nth-child(4) {
  top: 10px;
}

#nav-icon2 span:nth-child(5), #nav-icon2 span:nth-child(6) {
  top: 20px;
}

#nav-icon2.open span:nth-child(1),#nav-icon2.open span:nth-child(6) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-icon2.open span:nth-child(2),#nav-icon2.open span:nth-child(5) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#nav-icon2.open span:nth-child(1) {
  left: 2px;
  top: 5px;
}

#nav-icon2.open span:nth-child(2) {
  left: calc(50% - 1px);
  top: 5px;
}

#nav-icon2.open span:nth-child(3) {
  left: -50%;
  opacity: 0;
}

#nav-icon2.open span:nth-child(4) {
  left: 100%;
  opacity: 0;
}

#nav-icon2.open span:nth-child(5) {
  left: 2px;
  top: 15px;
}

#nav-icon2.open span:nth-child(6) {
  left: calc(50% - 1px);
  top: 14px;
}
