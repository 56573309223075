.home {
  &__card-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  &__card {
    padding-top: 0;
    margin: 10px;
    p {
      margin-top: 0;
      margin-bottom: 20px;
    }
    label {
      margin-right: 10px;
    }
    .p-card-content {
      padding-bottom: 0;
    }
  }
  &__charts-container {
    width: 100%;
    max-width: 900px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  &__chart-card {
    background-color: $theme-color-4;
    padding: 40px;
    border-radius: 30px;
    p {
      margin-top: 0;
      margin-bottom: 30px;
      text-align: center;
      font-weight: bold;
      font-size: 20px;
    }
  }

  &__docs-table {
    background-color: $theme-color-4;
    padding: 40px;
    border-radius: 30px;
    max-width: 100%;
    min-width: 800px;
  }
  &__templates-grid {
    max-width: 1100px;
  }
}

@media (max-width: 850px) {
  .home {
    .hide-mobile-home {
      display: none;
    }
    &__chart-card:first-of-type {
      margin-bottom: 40px;
    }
    th {
      max-width: 20px;
    }
    &__docs-table {
      min-width: unset;
    }
  }
}

@media (max-width: 576px) {
  .home {
    &__chart-card {
      padding: 20px;
    }
    .table-container {
      padding: 0 20px;
    }
  }
}

