.p-dialog {
  &-header {
    justify-content: center;
    &-icons {
      position: absolute;
      top: 16px;
      right: 16px;
    }
    padding-right: 80px;
    padding-left: 80px;
  }
  &-content {
    padding: 0;
  }
}
span.p-dialog-title {
  font-size: 1.25rem;
}

@media (max-width: 576px) {
  .p-dialog {
    max-width: 98%;
  }
}