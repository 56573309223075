@keyframes fadeIn {
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

@keyframes blink {
  50% { opacity: 0.5; }
}

.chat-page__user-response,
.chat-page__assistant-response {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeIn 0.5s ease forwards;
}
.chat-page {
  .page {
    width: calc(100vw - 580px);
  }
  &__assistant-actions {
    margin-bottom: 10px;
    fa-icon {
      margin-right: 10px;
    }
  }
  &__new-chat-advice {
    background-color: #111827;
    padding: 10px 14px;
    border: 1px solid #424b57;
    border-radius: 10px;
    display: flex;
    justify-self: center;
    align-items: center;
    &--title {
      margin-right: 10px;
    }
    .p-button {
      width: 28px;
      height: 28px;
    }
  }
    &__container {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: center;
    }
    &__bot-container {
        position: relative;
        padding-top: 40px;
        max-width: 780px;
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        width: 100%;
        height: calc(100vh - 288px);
        &.hidden-adivce {
          height: calc(100vh - 230px);
        }
    }
    &__scroll-box {
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      max-width: 780px;
      width: 100%;
      height: calc(100vh - 288px);
      overflow-y: auto;
      position: absolute;
      padding: 0 20px;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &.hidden-adivce {
        height: calc(100vh - 230px);
      }
    }
    &__scroll-box::-webkit-scrollbar {
      display: none;
    }
    &__footer {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: center;
        position: relative;
    }
    &__prompt-form {
        width: 780px;
        margin: 20px 0;
        display: flex;
        .p-inputtext {
            width: calc(100% - 46px);
            border-radius: 0;
        }
        .p-button {
          border-color: $brand-color-2;
          &.right {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            background-color: $brand-color-2;
          }
          &.left {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
            border-color: $theme-color-8;
            background-color: $theme-color-7;
            &:hover {
              border-color: $brand-color-2;
            }
          }
        }
    }

    &__user-response {
        display: flex;
        width: 100%;
        margin-bottom: 30px;
        justify-content: flex-end;
        span {
            background-color: $theme-color-7;
            padding: 14px;
            border: 1px solid $theme-color-8;
            border-radius: 10px;
        }
    }

    &__assistant-response {
        margin-bottom: 30px;
        width: calc(100% - 50px);
    }
    &__assistant-avatar {
      width: 40px;
      margin-right: 10px;
      margin-top: 20px;
      img {
        width: 100%;
      }
    }

    &__scroll-down {
        position: absolute;
        top: -46px;
    }

    &__assistant-loading-spinner {
        width: 100%;
        display: flex;
        justify-content: center;
        height: 120px;
        position: relative;
        p-progressSpinner {
          position: absolute;
          top: 0;
        }
    }
    &__sidebar {
      height: 100%;
      min-height: 100vh;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      align-content: flex-start;
      background-color: $theme-color-4;
      border-right: 1px solid $theme-color-8;
      overflow: hidden;
    }
    &__sidebar-collapse {
      display: flex;
      width: 100%;
      margin-top: 34px;
      justify-content: flex-end;
      .ng-fa-icon {
        padding: 6px;
        font-size: 32px;
      }
    }
    &__sidebar-content {
      padding-left: 44px;
      width: 280px;
    }
    &__sidebar-warning {
      margin-top: 22px;
    }
    &__threads {
      max-height: 80vh;
      overflow-y: scroll;
      padding-left: 54px;
      padding-right: 16px;
      width: 280px;
      margin-top: 20px;
    }
    &__thread {
      margin: 16px 0;
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid $theme-color-8;
      padding-top: 11px;
      &:first-of-type {
        border-top: none;
        margin-top: 0;
      }
      span {
        margin-right: auto;
        max-height: 40px;
        overflow: hidden;
      }
      .ng-fa-icon {
        margin-left: 10px;
      }
    }
    &__sidebar-actions {
      display: flex;
      justify-content: flex-end;
      padding: 0 26px;
      margin-top: 20px;
    }

    &__uploaded-file {
      position: absolute;
      background-color: $theme-color-1;
      z-index: 10;
      top: -12px;
      span {
        margin: 10px;
        padding-bottom: 10px;
      }
    }
}

.chatbot-view {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;

    .row {
      display: flex;
      align-items: center;
      width: 100%;
    }

    .chatbot-container {
      border-radius: 10px;
      width: 100%;
      background-color: $theme-color-4;
      max-width: 1200px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 40px;

      &__header {
        padding: 22px;
        width: 100%;
      }

      .chatbot-form {
        width: 80%;

        form {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
        }

        &__input {
          width: 100%;
        }
      }

      .chatbot-response {
        width: 80%;
        white-space: pre-wrap;
        margin-top: 20px;
        background-color: #111827;
        overflow: hidden;
        overflow-x: scroll;
        &__footer {
          width: 100%;
          fa-icon {
            padding: 5px;
          }
        }

        &__loading-spinner {
          margin: 40px 0;
        }
      }

    }
  }

  @media (max-width: 1400px) {
    .chat-page {
      &__scroll-box {
        max-width: 700px;
      }
    }
  }

  @media (max-width: 1200px) {
    .chat-page {
      &__sidebar {
        min-height: calc(100vh - 54px);
      }
      &__bot-container {
        height: calc(100vh - 260px);
      }
      &__threads {
        max-height: 56vh;
      }
      .chat-page__assistant-response:last-of-type {
        margin-bottom: 80px;
      }
      .page {
        width: calc(100vw - 300px);
        &.closed-nav {
          width: calc(100vw - 54px);
        }
      }
      .back-button {
        .p-button {
          padding: 0.75rem 0.75rem;
        }
        .p-button-label {
          display: none;
        }
        .p-button-icon {
          margin: 0;
        }
      }
    }
  }

  @media (max-width: 576px) {
    .chatbot-view {
      .chatbot-container {
        margin: 0 20px 20px;
        .chatbot-form {
          width: 90%;
          .form__item {
            margin-right: 0;
          }
        }
      }
    } 
  }

  @media (max-width: 430px) {
    .chat-page {
      &__bot-container {
        height: calc(100vh - 290px);
        padding-top: 0;
      }
      &__prompt-form {
        width: 90%;
      }
    }
  }
