.login-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: $theme-color-1;
  .login-register {
    width: 100%;
    display: flex;
    justify-content: end;
    margin-bottom: 10px;
    margin-top: 5px;
    p {
      margin: 0;
    }
    .p-button {
      padding: 0;
      margin-left: 5px;
    }
  }
  .login-button {
    margin: 0 0 10px auto;
  }
  .recover-panel {
    .back-button {
      position: absolute;
      top: 26px;
      right: 26px;
      .p-button.p-button-icon-only {
        width: 40px;
        height: 40px;
      }
    }
    p {
      margin-top: 40px;
      margin-bottom: 30px;
    }
    input {
      margin-bottom: 20px;
    }
    .login-button {
      margin-bottom: 0;
    }
  }
  .login-form {
    position: relative;
    padding: 30px;
    border-radius: 8px;
    background-color: $theme-color-4;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 440px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    h2 {
      margin-top: 0;
    }
    form {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }
    .form-group {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin-bottom: 20px;
      label {
        width: 100%;
        margin-bottom: 10px;
      }
      input, p-password, .p-password {
        width: 100%;
        height: 50px;
      }
    }
    .p-error {
      padding: 5px 0;
      width: 100%;
    }
  }
  .social-login {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .p-element {
      width: 100%;
      .p-button {
        justify-content: center;
      }
    }
    img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
    .separator {
      display: flex;
      align-items: center;
      text-align: center;
      margin: 20px 0;
      width: 100%;
    }
    
    .separator::before,
    .separator::after {
      content: '';
      flex: 1;
      border-bottom: 1px solid $brand-color-2;
      margin: 0 10px;
    }
    
    .separator span {
      font-size: 14px;
      color: $brand-color-2;
    }
  }
}
@media (max-width: 576px) {
  .login-wrapper {
    height: calc(100vh - 54px);
  }
}
