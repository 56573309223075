.form-container {
  border-radius: 10px;
  width: 100%;
  background-color: $theme-color-4;
  max-width: 1200px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 40px;
  form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
}
