$primaryColor: #2dd4bf !default;
$primaryLightColor: #5eead4 !default;
$primaryLighterColor: #99f6e4 !default;
$primaryLightestColor: rgba(45, 212, 191, .2) !default;
$primaryTextColor: #030712 !default;

$highlightBg:rgba(45, 212, 191, .16) !default;
$highlightTextColor: rgba(255,255,255,.87) !default;
$highlightFocusBg: rgba($primaryColor, .24) !default;

@import '../_variables';
@import './_fonts';
@import '../../../theme-base/_components';
@import '../_extensions';