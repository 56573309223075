  .page {
      padding: 14px 46px;

      &-header {
          width: 100%;
          margin-bottom: 20px;
          display: flex;
          align-items: center;

          .back-button {
              margin-left: auto;
          }
      }
  }

  .title {
    margin: 22px 0;
  }

  .col {
    display: flex;
    flex-wrap: wrap;
    &.col-6 {
      width: 50%;
    }
  }

  @media (max-width: 1200px) {
    .page-header {
      margin-bottom: 0;
    }
  }

  @media (max-width: 576px) {
    .page {
      padding: 0 0 14px;
      justify-content: center;
    }
    .page-header {
      padding: 0 20px;
    }
    .back-button {
      .p-button {
        padding: 0.75rem 0.75rem;
      }
      .p-button-label {
        display: none;
      }
      .p-button-icon {
        margin: 0;
      }
    }
    .tools-search {
      margin-bottom: 10px;
    }
  }
