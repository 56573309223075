.images-view {
    display: flex;
    flex-wrap: wrap;
    &__tooltip {
        display: flex;
        flex-wrap: wrap;
        width: 600px;
        span {
            font-size: 14px;
        }
    }
    &__sidebar {
        background-color: $theme-color-4;
        border-right: 1px solid $theme-color-8;
        height: 100vh;
        width: 400px;
        padding: 46px 20px 0;
        font-size: 14px;
        position: relative;
        overflow-y: auto;
        &-title {
            margin: 0;
        }
        &-form {
            margin: 20px 0;
            display: flex;
            flex-wrap: wrap;
        }
    }
    &__gallery {
        width: calc(100% - 400px);
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        justify-content: center;
        height: 100vh;
        padding: 10px 0;
        position: relative;
        &-images-container {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            justify-content: center;
            height: calc(100vh - 80px);
            overflow-y: auto;
        }
        &-footer {
            width: 100%;
            position: absolute;
            bottom: 12px;
            right: 0;
            display: flex;
            justify-content: center;
        }
    }
    
    &__favorite {
        position: absolute;
        right: 0;
        padding: 6px;
        z-index: 20;
        background-color: rgb(0, 0, 0, 0.5);
        border-bottom-left-radius: 6px;
        display: none;
        &-icon {
            font-size: 20px;
        }
    }
    &__image-container {
        display: flex;
        flex-wrap: wrap;
        gap: 10px; 
        margin: 10px;
        width: 300px;
        height: 300px;
        border-radius: 6px;
        overflow: hidden;
        border: 1px solid $theme-color-9;
        position: relative;
        &--mask {
            position: absolute;
            width: 100%;
            height: 100%;
            background: transparent;
            top: 0;
        }
        &--footer {
            position: absolute;
            bottom: 0;
            width: 100%;
            background-color: rgb(0, 0, 0, 0.5);
            padding: 6px 3px;
            display: none;
            justify-content: flex-end;
            fa-icon {
                font-size: 20px;
                padding: 0 3px;
                z-index: 20;
            }
        }
        &:hover {
            border-color: $brand-color-2;
            cursor: pointer;
            .images-view__image-container--mask {
                background: rgba(0, 0, 0, 0.3);
                z-index: 10;
            }
            .images-view__favorite {
                display: flex;
            }
            .images-view__image-container--footer {
                display: flex;
            }
        }
    }
    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        background-image: linear-gradient(45deg, #646262 25%, transparent 25%, transparent 75%, #646262 75%, #646262), linear-gradient(45deg, #646262 25%, transparent 25%, transparent 75%, #646262 75%, #646262);
        background-size: 20px 20px;
        background-position: 0 0, 10px 10px;
    }
    &__prompt-input {
        width: 100%;
        margin-bottom: 10px;
        textarea {
            width: 100%;
        }
    }
    &__input {
        width: 100%;
        .p-inputnumber,
        .p-inputwrapper {
            width: 100%;
        }
    }
    &__custom-seed {
        margin-bottom: 16px;
    }
    &__model {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        background: $theme-color-7;
        border-radius: 6px;
        border: 1px solid #424b57;
        padding: 15px 10px;
        position: relative;
        margin-bottom: 20px;
        min-height: 47px;
        &:hover {
            border-color: $brand-color-2;
            cursor: pointer;
        }
        &-delete {
            position: absolute;
            right: 10px;
            color: $danger-light-color;
            font-size: 18px;
            top: 12px;
        }
        &--less-padding {
            padding: 5px;
            .p-chip {
                margin: 5px;
            }
        }
    }
    &__model-panel {
        position: absolute;
        left: 282px;
        background: $theme-color-7;
        border-radius: 6px;
        border: 1px solid #424b57;
        padding: 20px;
        h3 {
            margin: 0;
        }
    }
    &__label {
        margin-bottom: 5px;
        width: 100%;
    }
    &__image-inputs {
        display: flex;
        flex-wrap: wrap;
    }
    &__image-input {
        display: flex;
        flex-wrap: wrap;
        width: 50%;
    }
    &__improve-ai {
        margin-bottom: 16px;
        width: 100%;
    }
    &__generate-button {
        margin-bottom: 20px;
    }
    
    .p-inputswitch {
        height: 17px;
    }
    .p-inputswitch-slider {
        height: 17px;
        width: 36px;
        &::before {
            width: 14px;
            height: 14px;
            top: 10px
        }
    }
    .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
        transform: translateX(14px);
    }

    .p-chip .p-chip-text {
        line-height: 14px;
        margin-top: 5px;
        margin-bottom: 5px;
        font-size: 12px;
    }
    .just-mobile {
        display: none;
    }
    .show-always {
        display: flex;
    }
}
.image-detail-modal {
    .p-dropdown {
        width: 100%;
    }
}
@media (max-width: 1200px) {
    .images-view {
        &__gallery {
            height: calc(100vh - 54px);
            &-images-container {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                justify-content: center;
                height: calc(100vh - 134px);
                overflow-y: auto;
            }
            &-footer {
                width: 100%;
                position: absolute;
                bottom: 12px;
                right: 0;
                display: flex;
                justify-content: center;
            }
        }
    }
}
@media (max-width: 1100px) {
    .images-view {
        &__gallery,
        &__sidebar {
            height: calc(100vh - 54px);
        }
    }
}

@media (max-width: 965px) {
    .images-view {
        flex-wrap: wrap;
        &__sidebar {
            width: calc(100vw - 340px);
        }
        &__gallery {
            width: 340px;
        }
    }
}
@media (max-width: 660px) {
    .images-view {
        flex-wrap: wrap;
        &__sidebar {
            width: 100%;
            height: unset;
            overflow: visible;
        }
        &__gallery {
            width: 100%;
            height: unset;
            padding: 0;
            &-images-container {
                height: unset;
            }
            &-header {
                width: 100vw;
                padding: 10px 20px;
                display: flex;
                justify-content: flex-end;
            }
        }
        &__image-container {
            width: 46vw;
            height: 46vw;
            margin: 1vw;
        }
        .just-mobile {
            display: flex;
        }
        .hide-mobile {
            display: none;
        }
    }
}