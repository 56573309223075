.container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    &--centered {
      justify-content: center;
    }

    &--darker {
      background-color: $theme-color-4;
      border: 1px solid $theme-color-6;
      border-radius: 10px;
    }
  }
  .cursor-pointer:hover {
    cursor: pointer;
  }
  .hidden {
    visibility: hidden;
  }
  .rotate-icon-right-90 {
    svg {
      -webkit-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      -o-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      transform: rotate(90deg);
    }
  }

  .rotate-icon-right-270 {
    svg {
      -webkit-transform: rotate(270deg);
      -moz-transform: rotate(270deg);
      -o-transform: rotate(270deg);
      -ms-transform: rotate(270deg);
      transform: rotate(270deg);
    }
  }

  .p-tabview .p-tabview-nav li {
    margin-right: 1px;
}

@media (max-width: 1200px) {
  .container {
    &--darker {
      width: 90%;
      margin: 0 10px;
    }
  }
}