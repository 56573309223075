@import 'abstracts/variables',
'abstracts/functions',
'abstracts/mixins',
'abstracts/placeholders';

@import 'themes/theme';

//@import 'vendors/bootstrap';

@import 'base/reset',
'base/typography',
'base/flag';

@import 'layout/common',
'layout/header',
'layout/footer',
'layout/form';

@import 'components/checkbox',
'components/button',
'components/icon',
'components/input',
'components/card',
'components/spinner',
'components/skeleton',
'components/navbar',
'components/text-editor',
'components/dialog-form',
'components/form-container',
'components/tabs',
'components/dialog';

@import 'pages/common',
'pages/chat-page',
'pages/editor-page',
'pages/home',
'pages/user-profile',
'pages/login',
'pages/register-page',
'pages/image-generator';


.mb-20 {
  margin-bottom: 20px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.bots-grid-page {
  padding: 60px;
  justify-content: center;
  align-items: center;

  .bots-grid {
    background-color: $theme-color-5;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    border-radius: 10px;
    max-width: 1400px;
    overflow: hidden;

    &--box {
      background-color: $theme-color-4;
      width: 25%;
      height: 230px;
      border-bottom: 1px solid $theme-color-5;
      border-right: 1px solid $theme-color-5;
      padding: 18px;

      &__bottom {
        border-bottom: none;
      }

      &__full-width-last-col {
        border-right: none;
      }
    }
  }
}



.box-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  &__item {
    width: 260px;
    height: 260px;
    border-radius: 10px;
    margin: 18px;
    padding: 10px;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: $brand-color-1;
    border-radius: 30px 30px 30px 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    z-index: 100;

    &:hover {
      border-color: $brand-color-2;
      background-color: $theme-color-4;
      cursor: pointer;
    }

    img {
      width: 180px;
      height: 180px;
    }

    span {
      width: 100%;
      text-align: center;
    }

    &--bot {
      align-content: flex-start;
      width: 300px;
      height: 300px;

      &.big {
        width: 636px;
      }

      .box-grid {
        &__item-header {
          display: flex;
          align-items: center;
          margin-top: 8px;
          width: 90%;
          height: 74px;
        }

        &__item-icon {
          margin-right: 16px;
          font-size: 24px;
        }

        &__item-body {
          width: 82%;
          text-align: justify;
          margin-top: 10px;
        }
      }
    }
  }

  &__item-container {
    position: relative;
  }

  &__favorite {
    position: absolute;
    top: 0;
    right: 0;
    margin: 32px;
    z-index: 100;
    font-size: 18px;
    cursor: pointer;
  }
}



.chatbot-response {
  margin-bottom: 20px;
  border: 1px solid grey;
  border-radius: 10px;
  padding: 20px;
  overflow-x: scroll;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  &__markdown {
    width: 100%;
  }

  &__footer {
    width: 100%;
  }

  fa-icon {
    margin-right: 10px;
  }
}

table {
  border-collapse: collapse;
  margin: 25px 0;

  thead tr {
    color: $theme-color-2;
    text-align: left;
  }

  tbody tr {
    border-bottom: 1px solid $theme-color-3;
  }

  tbody tr:last-of-type {
    border-bottom: 2px solid $theme-color-3;
  }
}