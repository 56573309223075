
.form {
    &__item {
      margin-bottom: 14px;
      margin-right: 10px;
      display: flex;
      flex-wrap: wrap;
      width: 300px;

      input,
      p-inputnumber,
      .p-dropdown,
      p-dropdown,
      .p-inputtextarea {
        span {
          width: 100%;
        }

        width: 100%;
      }

      &--full-width {
        max-width: 100%;
        margin-right: 0;

        .p-inputtext {
          width: 100%;
        }
      }
    }

    &__textarea {
      width: 100%;
      min-height: 200px;
    }

    &__footer {
      display: flex;
      margin-bottom: 14px;
      align-items: flex-end;
      width: 100%;
      margin-bottom: 22px;

      p-checkbox,
      label {
        margin-right: 10px;
      }

      .p-button {
        margin-top: auto;
      }
    }

    &__toggle-button-container {
      display: flex;
      flex-wrap: wrap;
      margin-right: auto;

      label {
        margin-bottom: 10px;
      }

      p-selectbutton {
        width: 100%;
      }
    }

    .p-selectbutton .p-button.p-highlight {
      background-color: $brand-color-2;
      border-color: $brand-color-2;
    }
  }

  .button {
    &--brand-color {
      .p-button {
        background-color: $brand-color-1;
        border: 1px solid $brand-color-1
      }
    }
  }

  .tiktok-stepper {
    width: 980px;
    padding: 10px;
  }


  .checkbox-group {
    &__checkbox {
      margin-right: 10px;
    }

    &__label {
      margin-right: 20px;
    }

    &__form-item {
      margin-bottom: 20px;
    }
  }

  .stepper-body {
    margin-bottom: 10px;
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
  }

  .generated-form {
    display: flex;
    flex-wrap: wrap;

    &__item {
      width: 300px;
      padding: 0 10px;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 20px;
    }

    &__item-label {
      width: 100%;
    }

    &__item-input {
      width: 100%;
    }

    &__footer {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 0 10px;
      label {
        margin-bottom: 10px;
      }
    }

    .p-inputnumber {
      width: 100%;

      span {
        width: 100%;
      }
    }

    .p-dropdown {
      width: 100%;
    }

    .generated-form {
      &__item-container {
        &--full-width {
          width: 100%;

          .p-inputtextarea {
            width: 100%;
            margin-right: 10px;
          }

          .generated-form__item {
            width: 100%;
          }
        }
      }
    }
  }

  
  @media (max-width: 1200px) {
    .tiktok-stepper {
      width: 100%;
      align-items: flex-start;
    }
  }

  @media (max-width: 576px) {
    .form {
      &__footer {
        flex-wrap: wrap;
      }
    }
    .generated-form__item {
        width: 100%;
        &-container {
          width: 100%;
        }
    }
  }