.register-page {
  height: 100vh;
  display: flex;
  align-content: flex-start;

  .page-header {
    height: fit-content;
  }

  .form-container {
    padding: 40px;
    width: 900px;

    .p-error {
      padding: 5px 0;
    }

    .p-field {
      width: 45%;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 20px;
      justify-content: space-between;
      align-content: flex-start;

      label {
        width: 100%;
        margin-bottom: 10px;
      }

      input {
        width: 100%;
      }

      p-password,
      .p-password {
        width: 100%;
      }

      .p-inputwrapper {
        width: 100%;
      }
      
      p-calendar {
        width: 100%;

        .p-calendar {
          width: 100%;
        }
      }
    }

    .form-row__2-col {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
}

@media (max-width: 576px) {
  .register-page {
    height: unset;

    .form-container {
      .p-field {
        width: 100%;
      }
    }
    .container {
      overflow: unset;
    }
    h1 {
      margin-top: 0;
    }
  }
}