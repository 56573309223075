.input-cg {
  position: relative;
  display: flex;
  align-items: center;
  
  img {
    position: absolute;
    width: 20px;
    left: 15px;
  }
  input {
    border-radius: $border-radius-1;
  }
}
